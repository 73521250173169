html
  min-height: 100vh

body
  width: 100%
  min-height: 100vh
  overflow-x: hidden
  overflow-y: scroll
  cursor: default

  &:has(up-progress-bar)
    &,
    a,
    .btn
      cursor: progress !important

[hidden]
  display: none !important

[up-show-for]:not(.up-switched), [up-hide-for]:not(.up-switched)
  // Hide up-show-for and up-hide-for elements by default, until Unpoly code has determined if the element is to be shown or hidden.
  display: none

[clickable-row]
  cursor: pointer

p,
ul,
ol
  &:last-child
    margin-bottom: 0

    .avoid-page-break > &
      margin-bottom: 1rem

h6
  line-height: 1.5

input[type='number']
  text-align: right
  -moz-appearance: textfield

input[type='file']
  cursor: pointer

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none

textarea
  min-height: 5rem

label.required
  &:not(.boolean)
    &::after
      content: ' *'
  &.boolean
    > span
      &::after
        content: ' *'
