@import ../environment

.tour
  & + &
    margin-top: spacer(3)

.tour--head
  display: flex
  justify-content: space-between
  margin-bottom: spacer(3)
  border-bottom: 1px solid $gray-400
  line-height: 2rem
  align-items: end
