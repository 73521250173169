@font-face
  font-display: swap
  font-family: 'Ubuntu'
  font-style: normal
  font-weight: 400
  src: local(''), url('/var/www/rfa-sachsen_s/releases/20241217121332/app/assets/stylesheets/../fonts/ubuntu-v20-latin-regular.woff2') format('woff2'), url('/var/www/rfa-sachsen_s/releases/20241217121332/app/assets/stylesheets/../fonts/ubuntu-v20-latin-regular.woff') format('woff')

@font-face
  font-display: swap
  font-family: 'Ubuntu'
  font-style: normal
  font-weight: 500
  src: local(''), url('/var/www/rfa-sachsen_s/releases/20241217121332/app/assets/stylesheets/../fonts/ubuntu-v20-latin-500.woff2') format('woff2'), url('/var/www/rfa-sachsen_s/releases/20241217121332/app/assets/stylesheets/../fonts/ubuntu-v20-latin-500.woff') format('woff')

@font-face
  font-display: swap
  font-family: 'Ubuntu'
  font-style: normal
  font-weight: 700
  src: local(''), url('/var/www/rfa-sachsen_s/releases/20241217121332/app/assets/stylesheets/../fonts/ubuntu-v20-latin-700.woff2') format('woff2'), url('/var/www/rfa-sachsen_s/releases/20241217121332/app/assets/stylesheets/../fonts/ubuntu-v20-latin-700.woff') format('woff')
