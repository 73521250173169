@import ../environment

.transactions:not(.transaction)
  border-top: 2px solid $gray-300

.transaction
  display: flex
  justify-content: space-between
  padding: spacer(3) 0

  &:first-child
    padding-top: 0

  &:last-child
    padding-bottom: 0

  & + &
    border-top: 1px solid $gray-300

.transaction--name, .transaction--purpose
  flex-basis: 0
  flex-grow: 1
  max-width: 100%

.transaction--value
  flex: 0 0 auto
  width: auto
  max-width: 100%
