@import ../environment

.simple-calendar

    --grey-background: #DDD
    --yellow-background: #FFFFC0

    table
        -webkit-border-horizontal-spacing: 0
        -webkit-border-vertical-spacing: 0
        border: 1px solid $gray-400
        border-collapse: collapse
        box-sizing: border-box
        max-width: 100%
        width: 100%
        min-height: 70vh

    tr
        border-collapse: collapse

    th
        padding: 6px
        border-bottom: 2px solid $gray-400
        border-collapse: collapse
        border-left: 1px solid $gray-400
        border-right: 1px solid $gray-400
        box-sizing: border-box
        text-align: left

    td
        padding: 6px
        vertical-align: top
        width: 14%

        border: 1px solid $gray-400

    .day
        height: 100px

    .today
        background: var(--yellow-background)

    .prev-month
        background: var(--grey-background)
        color: $gray-500

    .next-month
        background: var(--grey-background)
        color: $gray-500

    .calendar-heading
        font-weight: bold
        font-size: 1.5 * $font-size-base
