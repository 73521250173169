@import ../environment

.date-range
  display: flex
  flex-direction: row
  gap: 2rem
  align-items: center
  +media-breakpoint-down(xs)
    flex-direction: column
    gap: 0
    align-items: flex-start


.date-range--date
  width: 150px

