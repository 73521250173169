@use "sass:math"
@import environment
$screen-padding: 46px

=print
  &
    font-size: 16px // BS4 uses rems, so we want to make sure the base font size is correct

  body
    font-family: Nimbus Sans L, sans-serif !important
    padding: 0

  .container
    width: 100%

  p,
  .card,
  .form-group,
  .avoid-page-break
    page-break-inside: avoid

  .allow-page-break
    page-break-inside: auto

  .page-break-before
    page-break-before: always

  .card
    display: block

  .is-green
    color: $green

  body.-modal-open
    overflow-x: auto !important
    overflow-y: auto !important
    padding: 0 !important

    & :where(& > *:not(up-modal), up-modal-backdrop, up-modal-dismiss)
      display: none

    & :where(up-modal, up-modal-viewport, up-modal-content, up-modal-box)
      position: static
      width: 100%
      padding: 0
      overflow-x: auto !important
      overflow-y: auto !important
      display: block !important

@media print
  html
    +print

  @page
    // wkhtmltopdf will ignore these margins. To make any view print nicely
    // from a user's browser, we define the following margins.
    margin-left: 7mm
    margin-right: 7mm

  .row
    > .col-sm,
    > .col-md
      flex-basis: 0
      flex-grow: 1
    @for $i from 1 through 12
      > .col-sm-#{$i},
      > .col-md-#{$i}
        width: math.div(100% * $i, 12)

@media screen
  // We allow previewing letters in a browser.
  //
  // Regardless of any `--viewport-size` options, wkhtmltopdf's pages will fit about 900px of content.
  // When previewing letters in a browser, we emulate that by setting a maximum width.
  //
  // Note that your browser's rendering will not be the same as wkhtmltopdf's rendering.
  // There is absolutely no way your browser will render exactly as wkhtmltopdf. Do not do pixel-perfect layouts.
  //
  // However, the following is close enough and should support you in layouting letters using your browser as a preview.
  //
  html[data-layout='letter']
    +print

    padding: 60px

    body
      transform: translateX(-1px) // align page with 125% pdf preview in Chrome
      width: 990px
      margin: 0 auto
      padding: $screen-padding
      padding-left: ceil($screen-padding * 1.75) // wkhtmltopdf uses 17.5mm instead of 10mm (=> config/initializers/pdfkit.rb)
      min-height: 1400px
      box-shadow: 0 0 80px rgba(#000, .2)
